import React, { useState, MouseEvent } from 'react';
import { styled, useTheme, Theme } from '@mui/material/styles';
import { Avatar, Box, Grid, Menu, MenuItem, Typography, SxProps } from '@mui/material';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

// assets
// import EarningIcon from 'assets/images/icons/earning.svg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppOutlined';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyOutlined';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveOutlined';
import MainCard from "./MainCard";
import {axiosInstance} from "../api/backend/axios";

const CardWrapper = styled(MainCard)(({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '10px',
    '&:after': {
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.secondary,
        borderRadius: '10px',
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.secondary,
        borderRadius: '10px',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));

interface NonProcessedImportCardProps {
    isLoading: boolean;
    number: number | undefined
}



const NonProcessedImportCard: React.FC<NonProcessedImportCardProps> = ({ isLoading, number }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDownloadClick = async () => {
        try {
            const response = await axiosInstance.get('/admin/download-xml-import', {
                responseType: 'blob', // Nastavte typ odpovědi na blob pro stahování souborů
            });

            const contentDisposition = response.headers['content-disposition'];
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            const suggestedFileName = fileNameMatch ? fileNameMatch[1] : 'downloaded.xml'; // Defaultní název

            const blob = new Blob([response.data], { type: 'application/xml' }); // Nastavte typ na 'application/xml'

            // Vytvoření URL objektu pro stahování
            const url = window.URL.createObjectURL(blob);

            // Vytvoření odkazu a kliknutí na něj pro stažení souboru
            const a = document.createElement('a');
            a.href = url;
            a.download = suggestedFileName; // Použití názvu souboru z hlavičky odpovědi
            document.body.appendChild(a);
            a.click();

            // Uvolnění URL objektu
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Chyba při stahování souboru', error);
        }
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {/*{isLoading ? (*/}
            {/*    <SkeletonEarningCard />*/}
            {/*) : (*/}
            {/*    <CardWrapper border={false} content={false}>*/}
            {/*        /!* ...rest of the component... *!/*/}
            {/*    </CardWrapper>*/}
            {/*)}*/}

            <CardWrapper border={false} content={false}>
                <Box sx={{ p: 2.25 }}>
                    <Grid container direction="column">
                        <Grid item>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>{number}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            // ...theme.typography.commonAvatar,
                                            // ...theme.typography.mediumAvatar,
                                            backgroundColor: theme.palette.primary.dark,
                                            color: theme.palette.primary.main,
                                            borderRadius: '10px',
                                            zIndex: 1
                                        }}
                                        aria-controls="menu-earning-card"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreHorizIcon fontSize="inherit" />
                                    </Avatar>
                                    <Menu
                                        id="menu-earning-card"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        variant="selectedMenu"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                    >
                                        <MenuItem onClick={handleDownloadClick} disabled={number !== undefined && number < 1}>
                                            <GetAppTwoToneIcon sx={{ mr: 1.75 }} />Stáhnout XML
                                        </MenuItem>
                                        {/*<MenuItem onClick={handleClose} disabled={number !== undefined && number < 1}>*/}
                                        {/*    <PlayCircleOutlineIcon sx={{ mr: 1.75 }} /> Zpracovat ihned*/}
                                        {/*</MenuItem>*/}
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ mb: 1.25 }}>
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: 500,
                                    color: theme.palette.secondary.main
                                }}
                            >
                                Nezpracovaných importů
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </CardWrapper>
        </>
    );
};

export default NonProcessedImportCard;
