import React from 'react';
import { List, ListItem, Typography, useTheme, Link } from '@mui/material';
import {useTranslation} from "react-i18next";

const FooterLinks = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    const listItemStyle = {
        color: theme.palette.primary.main, // This will apply the color to the text
    };

    const linkStyle = {
        ...listItemStyle,
        textDecoration: 'none', // Remove underline from the link
        transition: 'color 0.3s', // Add transition for color change
        '&:hover': {
            color: theme.palette.primary.dark, // Change text color on hover
        },
    };

    return (
        <div>
            <Typography variant={"h3"}
                        sx={{
                            fontSize: '28px',
                            lineHeight: '36px',
                            fontWeight: 'bold',
                            color: '#fff',
                            textTransform: 'uppercase',
                            borderBottom: `4px solid ${theme.palette.primary.main}`,
                            width: 'fit-content',
                            // marginX: 'auto',
                            position: 'relative',
                            '@media (min-width: 1200px)': {
                                fontSize: '3xl',
                            },
                        }}
            >
                {t('infolinks')}
            </Typography>
            <List component="menu" aria-label="footer services">
                <ListItem>
                    <Link href="#doprava" sx={linkStyle}>
                        {t('shipping')}
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href="#obchodni_podminky" sx={linkStyle}>
                        {t('terms')}
                    </Link>
                </ListItem>
                <ListItem>
                    <Link href="/files/zpracovani-osobnich-udaju.pdf" sx={linkStyle}>
                        {t('gdpr')}
                    </Link>
                </ListItem>
            </List>
        </div>
    );
};

export default FooterLinks;
