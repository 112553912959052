import Slider from "../components/Slider";
import Brands from "../components/Brands";
import InfoContainer from "../components/InfoContainer";
import Gallery from "../components/Gallery";
import React from "react";
import RandomProductPage from "./RandomProductPage";

const HomePage = () => {
    return (
        <>
            <Slider />
            <Brands />
            <InfoContainer />
            {/*<Gallery />*/}
            <RandomProductPage />
        </>
    );
}

export default HomePage;