import {QueryError} from "./QueryError";

export {NotFoundError};

class NotFoundError extends QueryError {
    constructor(message: string, cause: string) {
        super(message, cause, 404);
        this.name = 'NotFoundError';
    }
}
