import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import * as React from "react";
import {CSSObject, styled, Theme} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {useState} from "react";
import ListItemLink from "../../components/ListItemLink";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CategoryIcon from '@mui/icons-material/Category';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import DashboardIcon from '@mui/icons-material/Dashboard';


const drawerWidth = 240;


const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export interface AdminMenuProps {
    open: boolean;
}


const menuItem = [
    {
        name: "Přehled",
        path: "",
        icon: <DashboardIcon />
    },
    {
        name: "Kategorie",
        path: "category",
        icon: <CategoryIcon/>
    },
    {
        name: "Produkty",
        path: "products",
        icon: <ShoppingBagIcon />
    }
]
const AdminMenu = (props: AdminMenuProps) => {
    const {open} = props;


    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader/>
            <List>
                {menuItem.map((item, index) => (
                    <ListItemLink primary={item.name} to={'/admin/' + item.path} icon={item.icon}
                                  sxItem={{
                                      minHeight: 48,
                                      justifyContent: open ? 'initial' : 'center',
                                      px: 2.5
                                  }}
                                  sxIcon={{
                                      minWidth: 0,
                                      mr: open ? 3 : 'auto',
                                      justifyContent: 'center',
                                  }}
                                  sxText={{
                                      opacity: open ? 1 : 0
                                  }}
                    />
                ))}
            </List>
            <Divider/>
        </Drawer>
    );
}

export default AdminMenu;