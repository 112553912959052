import {CatalogCategory, Category} from "../../generated";
import {axiosInstance, axiosInstanceWithoutCreadntials, handleRequest} from "../axios";
import {ADMIN_CATEGORY_BY_ID_URL, ADMIN_CATEGORY_URL, CATALOG_CATEGORY_BY_ID_URL, CATALOG_CATEGORY_URL} from "../url";
import {AxiosResponse} from "axios/index";


const getAllCatalogCategory = (): Promise<CatalogCategory> => {
    return handleRequest(() => axiosInstanceWithoutCreadntials.get<CatalogCategory>(CATALOG_CATEGORY_URL));
};

const getCatalogCategory = (categoryId:number): Promise<AxiosResponse<Category>> => {
    return handleRequest(() => axiosInstanceWithoutCreadntials.get(CATALOG_CATEGORY_BY_ID_URL(categoryId)));
}

const getAllCategory = (): Promise<Category[]> => {
    return handleRequest(() => axiosInstance.get<Category[]>(ADMIN_CATEGORY_URL));
};

const postCategory = (category:Category) => {
    return handleRequest(() => axiosInstance.post(ADMIN_CATEGORY_URL, category));
}

const getCategory = (categoryId:number): Promise<AxiosResponse<Category>> => {
    return handleRequest(() => axiosInstance.get(ADMIN_CATEGORY_BY_ID_URL(categoryId)));
}

const pathCategory = (categoryId:number, category:Category) => {
    return handleRequest(() => axiosInstance.patch(ADMIN_CATEGORY_BY_ID_URL(categoryId), category));
}

const deleteCategory = (categoryId:number) => {
    return handleRequest(() => axiosInstance.delete(ADMIN_CATEGORY_BY_ID_URL(categoryId)));
}


const CategoryService = {
    getAllCatalogCategory,
    getCatalogCategory,
    getAllCategory,
    postCategory,
    getCategory,
    pathCategory,
    deleteCategory
}

export default CategoryService;