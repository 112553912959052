import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface InfoToastProps {
    initialOpen: boolean;
    message: string;
    localStorageKey: string; // Klíč pro identifikaci specifického upozornění
}

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

export const InfoToast: React.FC<InfoToastProps> = ({ initialOpen, message, localStorageKey }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getInitialOpenState = (): boolean => {
        const lastClosed = localStorage.getItem(localStorageKey);
        if (lastClosed) {
            const lastClosedTime = new Date(parseInt(lastClosed, 10));
            if (new Date().getTime() - lastClosedTime.getTime() < ONE_DAY_IN_MS) {
                return false;
            }
        }
        return initialOpen;
    };

    const [open, setOpen] = React.useState(getInitialOpenState);
    const [closing, setClosing] = React.useState(false);

    const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setClosing(true);
        setTimeout(() => {
            setOpen(false);
            localStorage.setItem(localStorageKey, new Date().getTime().toString());
        }, 300); // Zpoždění pro dokončení animace zavření
    };

    const animationStyle: React.CSSProperties = closing
        ? { transition: 'opacity 0.3s', opacity: 0 }
        : { opacity: 1 };

    if (!open) {
        return null;
    }

    return (
        <div
            style={{
                ...animationStyle,
                position: "fixed",
                top: '10px',
                left: '50%',
                transform: 'translate(-50%, 0)',
                zIndex: 1400,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.secondary,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: isMobile ? '90%' : 'auto',
                maxWidth: '600px',
                padding: '6px 16px',
                borderRadius: '4px',
                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
            }}
        >
            <div
                id="message-id"
                style={{
                    overflow: 'hidden',
                    whiteSpace: 'normal',
                    flex: 1,
                    marginRight: '16px',
                    wordWrap: 'break-word'
                }}
            >
                {message}
            </div>
            <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
                style={{ padding: '8px' }}
            >
                <CloseIcon />
            </IconButton>
        </div>
    );
};
