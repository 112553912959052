import {Grid, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const ContactUs = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <Typography variant={"h3"}
                        sx={{
                            fontSize: '28px',
                            lineHeight: '36px',
                            fontWeight: 'bold',
                            color: '#fff',
                            textTransform: 'uppercase',
                            borderBottom: `4px solid ${theme.palette.primary.main}`,
                            width: 'fit-content',
                            marginX: 'auto',
                            position: 'relative',
                            '@media (min-width: 1200px)': {
                                fontSize: '3xl',
                            },
                        }}
                        id={"contactus"}
            >
                {t('contact_us_block.contact_us')}
            </Typography>
            <Grid container spacing={2} justifyContent="center" alignItems="center" pt={4}>
                <Grid item xs={4} justifyContent="flex-end" container alignItems="flex-end">
                    <img src="/assets/img/location-point.svg" alt="Ikonka lokace" loading="lazy" />
                </Grid>
                <Grid item xs={8}>
                    <Typography color={"#fff"}>Strojparts, s.r.o.</Typography>
                    <Typography color={"#fff"}>Hukvaldy 24</Typography>
                    <Typography color={"#fff"}>739 46 Hukvaldy</Typography>
                    <Typography color={"#fff"}>Moravskoslezský kraj, Česká republika</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center" pt={4}>
                <Grid item xs={4} justifyContent="flex-end" container alignItems="flex-end">
                    <img src="/assets/img/doc-icon.svg" alt="Ikonka lokace" loading="lazy" />
                </Grid>
                <Grid item xs={8}>
                    <Typography color={"#fff"}><strong>{t('contact_us_block.ico')}:</strong>&nbsp;17711452</Typography>
                    <Typography color={"#fff"}><strong>{t('contact_us_block.vat')}:</strong>&nbsp;CZ17711452</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center" pt={4}>
                <Grid item xs={4} justifyContent="flex-end" container alignItems="flex-end">
                    <img src="/assets/img/phone-call-svgrepo-com.svg" alt="Ikonka lokace" loading="lazy" />
                </Grid>
                <Grid item xs={8}>
                    <Typography color={theme.palette.primary.main}><strong style={{ color: 'white' }}>{t('contact_us_block.invoices')}:</strong>&nbsp;+420 724 794 775</Typography>
                    <Typography color={theme.palette.primary.main}><strong style={{ color: 'white' }}>{t('contact_us_block.store')}:</strong>&nbsp;+420 778 486 977</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center" pt={4}>
                <Grid item xs={4}
                      justifyContent="flex-end" container alignItems="flex-end">
                    <img src="/assets/img/letter-icon.svg" alt="Ikonka lokace" loading="lazy" />
                </Grid>
                <Grid item xs={8}>
                    <Typography color={theme.palette.primary.main}>
                        <a href={"mailto:info@strojparts.cz"}
                           style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
                            info@strojparts.cz
                        </a>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center" pt={4}>
                <Grid item>
                    <IconButton size={"large"} aria-label="instagram" href={"https://www.instagram.com/strojparts/"} target={"_blank"}>
                        <InstagramIcon color={"primary"} fontSize="inherit" />
                    </IconButton>
                    <IconButton size={"large"} aria-label="facebook" href={"https://www.facebook.com/profile.php?id=100089943339543"} target={"_blank"}>
                        <FacebookIcon  color={"primary"} fontSize="inherit" />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" pt={4}>
                <iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2584.584589112938!2d18.218456377688465!3d49.624435771446485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2aa047b8cd2c8bf%3A0xe25b63b2fade188!2sStrojparts%20s.r.o.!5e0!3m2!1scs!2scz!4v1708632742964!5m2!1scs!2scz" width="400" height="300" loading="lazy"></iframe>
            </Grid>
        </>
    );
}

export default ContactUs;