import {AuthDtoIn} from "../../api/backend/models/auth/AuthDtoIn";
import {AppDispatch} from "../store";
import {basicLogin} from "../../api/backend/client/loginClient";
import {setLoginBasic} from "../slice/userSlice";
import {VariantType} from "notistack";

export const userBasicLogin = (
    request: AuthDtoIn,
    showToast: (message?: string, variant?: VariantType) => void
) => {
    return (dispatch: AppDispatch) => {
        basicLogin(request)
            .then((response) => {
                if (response.data.user?.status === "PENDING") {
                    showToast("Účet ještě není aktivovaný. Je potřeba, aby registraci schválil hlavní administrátor.", "warning")
                    return;
                }
                dispatch(setLoginBasic(response.data));
            })
            .catch((reason) => {
                console.log("ERROR: ", reason);
                showToast("Chyba přihlášení.", "error");
            });
    };
};