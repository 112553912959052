import {Box, Button, FormControl, InputLabel, LinearProgress, Select, SelectChangeEvent, Typography} from "@mui/material";
import {useToast} from "../../components/toast/useToast";
import {useQuery} from "@tanstack/react-query";
import {Category, Product} from "../../api/generated";
import {NotFoundError} from "../../api/backend/NotFound";
import CategoryService from "../../api/backend/service/CategoryService";
import {useEffect, useState} from "react";
import {DataGrid, GridActionsCellItem, GridAddIcon, GridColDef, GridRowId, GridRowModesModel, GridRowsProp, GridToolbarContainer} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {styled} from "@mui/material/styles";
import AdminAddCategoryDialog from "../../components/Category/AdminAddCategoryDialog";
import ProductService from "../../api/backend/service/ProductService";
import AdminAddProductDialog from "../../components/Product/AdminAddProductDialog";
import AdminEditProductDialog from "../../components/Product/AdminEditProductDialog";
import CategoryMutation from "../../api/backend/service/CategoryMutation";
import ProductMutation from "../../api/backend/service/ProductMutation";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";


interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
    onSelectCategory: (categoryId: string) => void;
    categoryId: string;
}

function EditToolbar(props: EditToolbarProps) {
    const { data, status, isLoading, error, isError} =
        useQuery<Category[], NotFoundError>({ queryKey: ['category'], queryFn: () => CategoryService.getAllCategory()})

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    useEffect(() => {
        setSelectedCategory(props.categoryId);
    }, [props.categoryId]);

    // Handler pro změnu hodnoty výběrového seznamu
    const handleChange = (event: SelectChangeEvent) => {
        const categoryId = event.target.value as string;
        setSelectedCategory(categoryId);
        props.onSelectCategory(categoryId);
    };

    const sortedData = data
        ? data
            .slice()
            .filter(category => category.name !== undefined) // Filtruj kategorie bez definovaného názvu
            .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0)) // Použijte localeCompare jen pokud jsou oba názvy definované
        : [];


    return (
        <GridToolbarContainer>
            <AdminAddProductDialog open={openDialog} setOpen={setOpenDialog}/>
            <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                    <Button color="primary" startIcon={<GridAddIcon/>} onClick={handleOpenDialog} variant="contained">
                        Přidat produkt
                    </Button>
                </Grid>
                <Grid item>
                    <FormControl style={{minWidth: 300}}>
                        <InputLabel id="category-select-label">Kategorie</InputLabel>
                        <Select
                            labelId="category-select-label"
                            id="category-select"
                            label="Category"
                            defaultValue=""
                            value={selectedCategory}
                            onChange={handleChange}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200
                                    },
                                },
                            }}
                        >
                            <MenuItem value="">Žádná kategorie</MenuItem>
                            {sortedData.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </GridToolbarContainer>


    );
}
const ProductPage = () => {

    const { showToast } = useToast();
    const [categoryId, setCategoryId] = useState<number | null>(null);

    // pouziti async () => ... pouziti anonymni funkce zajisti ze se funkce spusti az kdyz ji react-query bude potrebovat.
    const { data, status, isLoading, error, isError} =
        useQuery<Product[], NotFoundError>(
            {
                queryKey: ['products', categoryId],
                queryFn: () => ProductService.getAdminProduct(categoryId, null)
            })
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product>({});
    const { deleteProductMutation } = ProductMutation();

    if (isError) {
        showToast(error?.statusCode.toString() + " " + error?.name, "error");
    }

    const handleEditClick = (id: GridRowId) => {
        console.log("edit", id);
        if (data === undefined) return;
        const row = data.find(row => row.id === id);
        if (row !== undefined) {
            setSelectedProduct(row);
        } else {
            return;
        }
        setOpenEditDialog(true);
    };
    const handleDeleteClick = async (id: GridRowId) => {
        const productId = typeof id === "string" ? parseInt(id, 10) : id;
        try {
            await deleteProductMutation.mutateAsync(productId);
            showToast("Produkt byl smazán.", "success");
        } catch (error) {
            console.error("Chyba při mutaci", error);
            showToast("Napodařilo se smazat produkt.", "error");
        }

        console.log("delete", id);
    };

    const handleCategorySelect = (categoryId: string) => {
        console.log("Selected category:", categoryId);
        const id = categoryId == "" ? null : parseInt(categoryId);
        setCategoryId(id);
    };


    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID produktu', flex: 1, minWidth: 50, maxWidth: 120, editable: false },
        { field: 'pohodaId', headerName: 'ID Pohoda', flex: 1, minWidth: 50, maxWidth: 120, editable: false },
        { field: 'name', headerName: 'Název produktu', flex: 1, minWidth: 150, maxWidth: 320, editable: false },
        { field: 'code', headerName: 'Katalogové číslo', flex: 1, minWidth: 50, maxWidth: 250, editable: false },
        { field: 'quality', headerName: 'Kvalita', flex: 1, minWidth: 50, /*maxWidth: 450,*/ editable: false },
        { field: 'note', headerName: 'Kódy přečíslení', flex: 1, minWidth: 50, /*maxWidth: 450,*/ editable: false },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    // pokud je v bunce odkaz tak pouzit toto
    // const handleCellClick = (param:GridCellParams, event:React.MouseEvent) => {
    //     param.field === "Total" && event.stopPropagation();
    // };

    const StyledDataGrid = styled(DataGrid)({
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
    });

    return (
        <Box>
            <Typography variant={"h4"}>Produkty</Typography>
            <StyledDataGrid
                rows={data || []}
                columns={columns}
                // editMode="cell"
                getRowId={(row:any) => row.id}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20]}
                // checkboxSelection
                slots={{
                    loadingOverlay: LinearProgress,
                    toolbar: (props) => <EditToolbar {...props} onSelectCategory={handleCategorySelect} categoryId={categoryId == null ? "" : categoryId.toString()} />,
                }}
                // checkboxSelection
                // onCellClick={handleCellClick}
                disableRowSelectionOnClick
                disableColumnSelector
                disableColumnFilter
                disableColumnMenu
                disableDensitySelector
                hideFooterSelectedRowCount
                loading={isLoading}
            />
            <AdminEditProductDialog open={openEditDialog} setOpen={setOpenEditDialog} product={selectedProduct} />
        </Box>
    );
}

export default ProductPage;