import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, InputLabel,
    LinearProgress, Select,
    Typography
} from "@mui/material";
import {
    DataGrid, GridActionsCellItem,
    GridAddIcon, GridCellParams,
    GridColDef, GridRowId, GridRowModes,
    GridRowModesModel, GridRowSelectionModel,
    GridRowsProp,
    GridToolbarContainer
} from "@mui/x-data-grid";
import {useToast} from "../../components/toast/useToast";
import {QueryClient, useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Category, Product} from "../../api/generated";
import CategoryService from "../../api/backend/service/CategoryService";
import {NotFoundError} from "../../api/backend/NotFound";
import {QueryError} from "../../api/backend/QueryError";
import axios from "axios";
import CategoryMutation from "../../api/backend/service/CategoryMutation";
import TextField from "@mui/material/TextField";
import {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import * as Yup from "yup";
import {useFormik} from "formik";
import AdminAddCategoryDialog from "../../components/Category/AdminAddCategoryDialog";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {styled} from "@mui/material/styles";
import AdminEditCategoryDialog from "../../components/Category/AdminEditCategoryDialog";


const initialRows: GridRowsProp = [
    {
        id: 1,
        name: "Jmeno",
        parents: "TEST<",
        description: "BLABAOJSF AISJG pois aopdihfdlg hisaugddiofg ",
        age: 25,
    },
    {
        id: 2,
        name: "Jmeno2",
        age: 25,
    },
    {
        id: 3,
        name: "Jmeno3",
        age: 25,
    },
];

interface EditToolbarProps {
    setRows?: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel?: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
    selectedRows: GridRowSelectionModel
}

function EditToolbar(props: EditToolbarProps) {
    const { data, status, isLoading, error, isError} =
        useQuery<Category[], NotFoundError>({ queryKey: ['category'], queryFn: () => CategoryService.getAllCategory()})

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleDeleteCategory = () => {
        console.log("delete", props.setRows, props.setRowModesModel, props.selectedRows);
    }

    return (
        <GridToolbarContainer>
            <AdminAddCategoryDialog open={openDialog} setOpen={setOpenDialog} />
            <Button color="primary" startIcon={<GridAddIcon/>} onClick={handleOpenDialog} variant={"contained"}>
                Nová kategorie
            </Button>
            {/*<Button color="primary" startIcon={<GridAddIcon/>} onClick={handleDeleteCategory} variant={"contained"}>*/}
            {/*    Smazat kategorie*/}
            {/*</Button>*/}
        </GridToolbarContainer>
    );
}

const CategoryPage = () => {
    const { showToast } = useToast();


    // pouziti async () => ... pouziti anonymni funkce zajisti ze se funkce spusti az kdyz ji react-query bude potrebovat.
    const { data, status, isLoading, error, isError} =
        useQuery<Category[], NotFoundError>({ queryKey: ['category'], queryFn: () => CategoryService.getAllCategory()})
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [rows, setRows] = useState(initialRows);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<Category>({});
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const { deleteCategoryMutation } = CategoryMutation();


    if (isError) {
        showToast(error?.statusCode.toString() + " " + error?.name, "error");
    }

    const handleEditClick = (id: GridRowId) => {
        console.log("edit", id);
        if (data === undefined) return;
        const row = data.find(row => row.id === id);
        if (row !== undefined) {
            setSelectedCategory(row);
        } else {
            return;
        }
        setOpenEditDialog(true);
    };

    const handleDeleteClick = async (id: GridRowId) => {
        const categoryId = typeof id === "string" ? parseInt(id, 10) : id;
        try {
            await deleteCategoryMutation.mutateAsync(categoryId);
            showToast("Kategorie byla smazána.", "success");
        } catch (error) {
            console.error("Chyba při mutaci", error);
            showToast("Napodařilo se smazat kategorii.", "error");
        }

        console.log("delete", id);
    };


    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID kategorie', flex: 1, minWidth: 50, maxWidth: 120, editable: false },
        { field: 'name', headerName: 'Název kategorie', flex: 1, minWidth: 50, maxWidth: 250, editable: false },
        { field: 'parents', headerName: 'Nadřazené kategorie', flex: 1, minWidth: 50, /*maxWidth: 450,*/ editable: false },
        { field: 'description', headerName: 'Popis kategorie', flex: 2.1, minWidth: 50, editable: false },
        // {
        //     field: 'age',
        //     headerName: 'Age',
        //     type: 'number',
        //     width: 80,
        //     align: 'left',
        //     headerAlign: 'left',
        //     editable: false,
        // },
        // {
        //     field: 'joinDate',
        //     headerName: 'Join date',
        //     type: 'date',
        //     width: 180,
        //     editable: false,
        // },
        // {
        //     field: 'role',
        //     headerName: 'Department',
        //     width: 220,
        //     editable: false,
        //     type: 'singleSelect',
        //     valueOptions: ['Market', 'Finance', 'Development'],
        // },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    // pokud je v bunce odkaz tak pouzit toto
    // const handleCellClick = (param:GridCellParams, event:React.MouseEvent) => {
    //     param.field === "Total" && event.stopPropagation();
    // };

    const StyledDataGrid = styled(DataGrid)({
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
    });

    return (
        <Box>
            <Typography variant={"h4"}>Kategorie</Typography>
            <StyledDataGrid
                rows={data || []}
                columns={columns}
                // editMode="cell"
                getRowId={(row:any) => row.id}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 20]}
                // checkboxSelection
                slots={{
                    loadingOverlay: LinearProgress,
                    toolbar: () => (
                        <EditToolbar
                            selectedRows={rowSelectionModel}
                        />
                    ),
                }}
                // checkboxSelection
                rowSelection
                // onCellClick={handleCellClick}
                disableRowSelectionOnClick
                disableColumnSelector
                disableColumnFilter
                disableColumnMenu
                disableDensitySelector
                hideFooterSelectedRowCount
                loading={isLoading}
            />
            <AdminEditCategoryDialog open={openEditDialog} setOpen={setOpenEditDialog} category={selectedCategory} />
        </Box>
    );
}

export default CategoryPage;