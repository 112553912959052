import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Category, Product} from "../../generated";
import CategoryService from "./CategoryService";
import {QueryError} from "../QueryError";


const CategoryMutation = () => {
    const queryClient = useQueryClient();

    const addCategoryMutation = useMutation({
        mutationFn: (newTodo: Category) => {
            return CategoryService.postCategory(newTodo);
        },
        onError: (error: QueryError) => {
            console.error("ERROR", error);
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['category'] });
            console.log("success", data, variables, context);
        }
    });

    const editCategoryMutation = useMutation<Category, QueryError, [number, Category]>({
        mutationFn: (args: [number, Category]) => {
            const [categoryId, newTodo] = args;
            return CategoryService.pathCategory(categoryId, newTodo);
        },
        onError: (error: QueryError) => {
            console.error("ERROR", error);
        },
        onSuccess: (data, variables, context) => {
            const [categoryId, updatedCategory] = variables;
            queryClient.setQueryData<Category[]>(['category'], oldData => {
                return oldData?.map(category =>
                    category.id === categoryId ? updatedCategory : category
                );
            });
            queryClient.invalidateQueries({ queryKey: ['category'] });
            console.log("success", data, variables, context);
        }
    });

    const deleteCategoryMutation = useMutation<void, QueryError, number>({
        mutationFn: (id:number) => {
            return CategoryService.deleteCategory(id);
        },
        onError: (error: QueryError) => {
            console.error("ERROR", error);
        },
        onSuccess: (_, id) => {
            if (id !== undefined) {
                queryClient.setQueryData<Category[]>(['category'], oldData => {
                    return oldData?.filter(category => category.id !== id);
                });
                queryClient.invalidateQueries({ queryKey: ['category'] });
                console.log("success", id);
            }
        }
    });

    // Return the mutation function for external use
    return {
        addCategoryMutation, editCategoryMutation, deleteCategoryMutation
    };
};

export default CategoryMutation;
