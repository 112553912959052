export const BASIC_LOGIN_URL = `/auth/authenticate`;
export const REGISTER_URL = `/auth/register`;


export const ADMIN_USER_URL = `/admin/user`;
export const ADMIN_USER_ADMINACCOUNT = (userId:number) => `/admin/user/${userId}/account`;
export const ADMIN_PERMISION = (permisionId:number) => `/admin/permission/${permisionId}`;
export const ADMIN_USER_STATUS = (userId:number) => `/admin/user/${userId}/status`;

// CATEGORY
export const ADMIN_CATEGORY_URL = '/admin/category';
export const CATALOG_CATEGORY_URL = '/category';
export const ADMIN_CATEGORY_BY_ID_URL = (categoryId : number) => `/admin/category/${categoryId}`
export const CATALOG_CATEGORY_BY_ID_URL = (categoryId : number) => `/category/${categoryId}`

// PRODUCT
export const ADMIN_PRODUCT_URL = '/admin/product';
export const CATALOG_PRODUCT_URL = '/product';
export const CATALOG_PRODUCT_BY_ID_URL = (productId : string | undefined) => `/product/${productId}`

export const ADMIN_PRODUCT_BY_ID_URL = (productId : number) => `/admin/product/${productId}`

// OTHER
export const ADMIN_METRICS_URL = '/admin/metrics';
export const INQUIRY_FORM_URL = '/inquiry';