import {AxiosResponse} from "axios";
import {axiosInstance} from "../axios";

import {BASIC_LOGIN_URL} from "../url";
import {AuthDtoIn} from "../models/auth/AuthDtoIn";
import {AuthDtoOut} from "../models/auth/AuthDtoOut";

export const basicLogin = (request: AuthDtoIn): Promise<AxiosResponse<AuthDtoOut>> => {
    const resp = axiosInstance.post(BASIC_LOGIN_URL, request)
    console.log("RESPONSE: " + resp)
    return resp;
}