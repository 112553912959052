import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Product} from "../../generated";
import {QueryError} from "../QueryError";
import ProductService from "./ProductService";


const ProductMutation = () => {
    const queryClient = useQueryClient();

    const addProductMutation = useMutation({
        mutationFn: (product: Product) => {
            return ProductService.postProduct(product);
        },
        onError: (error: QueryError) => {
            console.error("ERROR", error);
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: ['products'] });
            console.log("success", data, variables, context);
        }
    });

    const editProductMutation = useMutation<Product, QueryError, [number, Product]>({
        mutationFn: (args: [number, Product]) => {
            const [productId, product] = args;
            return ProductService.pathProduct(productId, product);
        },
        onError: (error: QueryError) => {
            console.error("ERROR", error);
        },

        onSuccess: (data, variables, context) => {
            const [productId, updatedProduct] = variables;

            // Aktualizace dat v cache pro dotaz 'products'
            queryClient.setQueryData<Product[]>(['products'], oldData => {
                return oldData?.map(product =>
                    product.id === productId ? updatedProduct : product
                );
            });
            queryClient.invalidateQueries({ queryKey: ['products'] });
            console.log("success", data, variables, context);
        }
    });

    const deleteProductMutation = useMutation<void, QueryError, number>({
        mutationFn: (id:number) => {
            return ProductService.deleteProduct(id);
        },
        onError: (error: QueryError) => {
            console.error("ERROR", error);
        },
        onSuccess: (_, id) => {
            if (id !== undefined) {
                queryClient.setQueryData<Product[]>(['products'], oldData => {
                    return oldData?.filter(product => product.id !== id);
                });
                queryClient.invalidateQueries({ queryKey: ['products'] });
                console.log("success", id);
            }
        }
    });

    // Return the mutation function for external use
    return {
        addProductMutation: addProductMutation,
        editProductMutation: editProductMutation,
        deleteProductMutation,
    };
};

export default ProductMutation;
