import DashboardIcon from '@mui/icons-material/Dashboard';
import {Box, Typography} from "@mui/material";
import MainCard from "../../components/MainCard";
import NonProcessedImportCard from "../../components/NonProcessedImportCard";
import Grid from "@mui/material/Grid";
import DashboardCard from "../../components/DashboardCard";
import {useQuery} from "@tanstack/react-query";
import {BusinessMetrics, Product} from "../../api/generated";
import {NotFoundError} from "../../api/backend/NotFound";
import ProductService from "../../api/backend/service/ProductService";
import OtherService from "../../api/backend/service/OtherService";
import {useToast} from "../../components/toast/useToast";

const Dashboard = () => {
    const { showToast } = useToast();
    const { data, status, isLoading, error, isError} =
        useQuery<BusinessMetrics, NotFoundError>({ queryKey: ['businessMetrics'], queryFn: () => OtherService.getBusinessMetrics()})

    if (isError) {
        showToast("Chyba při komunikaci.", "error");
    }

    return (
        <Box>
            <Typography variant={"h4"}>Přehled</Typography>
            <Grid container direction="row"  spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <NonProcessedImportCard isLoading={isLoading} number={data?.nonProcessedImport}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <DashboardCard isLoading={isLoading} number={data?.totalProducts} text={"Počet produktů"} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                    <DashboardCard isLoading={isLoading} number={data?.totalCategories} text={"Počet kategorií"} />
                </Grid>
            </Grid>

        </Box>
    );
}

export default Dashboard;