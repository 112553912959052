import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Dispatch, SetStateAction, useState} from "react";
import {Category} from "../../api/generated";
import CategoryMutation from "../../api/backend/service/CategoryMutation";
import {useToast} from "../toast/useToast";
import ProductMutation from "../../api/backend/service/ProductMutation";


interface AdminAddProductDialogProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>;
}
const AdminAddProductDialog = (props:AdminAddProductDialogProps) => {
    const { open, setOpen } = props;
    const { showToast } = useToast();
    const { addProductMutation } = ProductMutation();

    const handleAddClick = () => {
        console.log("add click");
    };

    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
    };

    const handleSubmit = () => {
        formik.submitForm();
    }


    const ProductSchema = Yup.object().shape({
        name: Yup.string().required("Povinné")
    });

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: "",
            code: "",
            ean: "",
            quality: "",
            note: "",
            active: false
        },
        validationSchema: ProductSchema,
        onSubmit: async (values) => {
            try {
                console.log("submit", values);
                await addProductMutation.mutateAsync(values); // TODO zavolat mutaci vytvoreni produktu
                showToast("Produkt vytvořeny.", "success");
                formik.resetForm();
                handleClose();
            } catch (error) {
                console.error("Chyba při mutaci", error);
                showToast("Produkt se nepodařilo vytvořit.", "error");
            }
        }
    });

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Nový produkt</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Vytvoření nového produktu.
                </DialogContentText>
                <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Název produktu"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="code"
                        label="Katalogové číslo"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="ean"
                        label="EAN"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.ean}
                        onChange={formik.handleChange}
                        error={formik.touched.ean && Boolean(formik.errors.ean)}
                        helperText={formik.touched.ean && formik.errors.ean}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="quality"
                        label="Kvalita"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.quality}
                        onChange={formik.handleChange}
                        error={formik.touched.quality && Boolean(formik.errors.quality)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="note"
                        label="Kódy přečíslení"
                        type="text"
                        multiline
                        minRows={2}
                        fullWidth
                        variant="standard"
                        value={formik.values.note}
                        onChange={formik.handleChange}
                        error={formik.touched.note && Boolean(formik.errors.note)}
                        helperText={formik.touched.note && formik.errors.note}
                    />

                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Zavřit</Button>
                <Button onClick={handleSubmit}>Vytvořit produkt</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdminAddProductDialog;