import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./theme/theme";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./router/AppRouter";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import ToastProvider from "./components/toast/ToastProvider";
import {store, persistor} from "./redux/store";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


const queryClient = new QueryClient()

root.render(
    // <React.StrictMode>
    //   <App />
    // </React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>

            <ThemeProvider theme={theme}>
                <ToastProvider>
                    <CssBaseline/>
                    <I18nextProvider i18n={i18next}>
                        <BrowserRouter basename="/">
                            <QueryClientProvider client={queryClient}>
                                <AppRouter/>
                                <ReactQueryDevtools initialIsOpen={false} />
                            </QueryClientProvider>
                        </BrowserRouter>
                    </I18nextProvider>
                </ToastProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
