import {BusinessMetrics, InquiryForm, Product} from "../../generated";
import {axiosInstance, axiosInstanceWithoutCreadntials, handleRequest} from "../axios";
import {ADMIN_METRICS_URL, ADMIN_PRODUCT_URL, INQUIRY_FORM_URL} from "../url";

const getBusinessMetrics = (): Promise<BusinessMetrics> => {
    return handleRequest(() => axiosInstance.get<BusinessMetrics>(ADMIN_METRICS_URL));
};

const postInquiryForm = (inquiryForm:InquiryForm): Promise<void> => {
    return handleRequest(() => axiosInstanceWithoutCreadntials.post(INQUIRY_FORM_URL, inquiryForm));
}

const OtherService = {
    getBusinessMetrics,
    postInquiryForm
}

export default OtherService;