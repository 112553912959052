import React from "react";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, FormControl, SelectChangeEvent, useTheme, makeStyles } from "@mui/material";
import { US, CZ } from 'country-flag-icons/react/3x2';

const LanguageSwitcher: React.FC = () => {
    const { i18n } = useTranslation();
    const theme = useTheme();

    const handleLanguageChange = (e: SelectChangeEvent<string>) => {
        const newLang = e.target.value;
        i18n.changeLanguage(newLang);
    };

    return (
        <FormControl>
            <Select
                value={i18n.language}
                onChange={handleLanguageChange}
                // label="Language"
                id="language-switcher"
                sx={{
                    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                    },
                }}
                IconComponent={() => null} // Odebrat šipku
            >
                <MenuItem value="en">
                    <US width={30} height={20} /> {/* Nastavte šířku a výšku ikony */}
                </MenuItem>
                <MenuItem value="cs">
                    <CZ width={30} height={20} /> {/* Nastavte šířku a výšku ikony */}
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSwitcher;
