import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import {useFormik} from "formik";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Category} from "../../api/generated";
import CategoryMutation from "../../api/backend/service/CategoryMutation";
import {useToast} from "../toast/useToast";


interface AdminAddCategoryDialogProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    category: Category
}
const AdminEditCategoryDialog = (props:AdminAddCategoryDialogProps) => {
    const { open, setOpen, category } = props;
    const { showToast } = useToast();
    const { editCategoryMutation } = CategoryMutation();

    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
    };

    const handleSubmit = () => {
        console.log("handleSubmit");
        formik.submitForm();
    }


    const CategorySchema = Yup.object().shape({
        name: Yup.string().required("Povinné")
    });

    const formik = useFormik({
        initialValues: {
            id: category.id,
            name: category.name,
            description: category.description,
            parents: category.parent
        },
        validationSchema: CategorySchema,
        onSubmit: async (values) => {
            try {
                console.log("submit", category.id);
                if (category.id === undefined) return;
                console.log("submit", values);
                await editCategoryMutation.mutateAsync([category?.id, values]);
                showToast("Kategorie vytvořena.", "success");
                formik.resetForm();
                handleClose();
            } catch (error) {
                console.error("Chyba při mutaci", error);
                showToast("Napodařilo se vytvořit kategorii.", "error");
            }
        }
    });

    useEffect(() => {
        formik.setValues({
            id: category.id,
            name: category.name,
            description: category.description,
            parents: category.parent
        });
    }, [category, formik.setValues]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Editace kategorie</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {category.id} - {category.name}
                </DialogContentText>
                <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Název kategorie"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Popis kategorie"
                        type="text"
                        multiline
                        minRows={2}
                        fullWidth
                        variant="standard"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}

                    />

                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Zavřit</Button>
                <Button onClick={handleSubmit}>Editovat kategorií</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AdminEditCategoryDialog;