import {OptionsObject, useSnackbar, VariantType} from "notistack";
import {useCallback} from "react";

export function useToast() {
    const {enqueueSnackbar} = useSnackbar();

    const showToast = useCallback(
        (message?: string | null, variant: VariantType = "success") => {
            if (!message) return;
            let options: OptionsObject = {variant};
            if (variant === "success" || variant === "warning") {
                options = {
                    ...options,
                    autoHideDuration: 4000,
                    disableWindowBlurListener: true,
                };
            } else if (variant === "error") {
                options = {
                    ...options,
                    autoHideDuration: 12000,
                }
            }
            message && enqueueSnackbar(message, options);
        }, [enqueueSnackbar]
    );

    return {showToast};
}