import React, { useState, MouseEvent } from 'react';
import { styled, useTheme, Theme } from '@mui/material/styles';
import { Avatar, Box, Grid, Menu, MenuItem, Typography, SxProps } from '@mui/material';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

// assets
// import EarningIcon from 'assets/images/icons/earning.svg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppOutlined';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyOutlined';
import PictureAsPdfTwoToneIcon from '@mui/icons-material/PictureAsPdfOutlined';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveOutlined';
import MainCard from "./MainCard";

const CardWrapper = styled(MainCard)(({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '10px',
    '&:after': {
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.secondary,
        borderRadius: '10px',
        top: -85,
        right: -95,
        [theme.breakpoints.down('sm')]: {
            top: -105,
            right: -140
        }
    },
    '&:before': {
        position: 'absolute',
        width: 210,
        height: 210,
        background: theme.palette.secondary,
        borderRadius: '10px',
        top: -125,
        right: -15,
        opacity: 0.5,
        [theme.breakpoints.down('sm')]: {
            top: -155,
            right: -70
        }
    }
}));

interface EarningCardProps {
    isLoading: boolean;
    number: number | undefined;
    text: string;
}
// TODO udelat isLoading
const DashboardCard: React.FC<EarningCardProps> = ({ isLoading, number, text }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {/*{isLoading ? (*/}
            {/*    <SkeletonEarningCard />*/}
            {/*) : (*/}
            {/*    <CardWrapper border={false} content={false}>*/}
            {/*        /!* ...rest of the component... *!/*/}
            {/*    </CardWrapper>*/}
            {/*)}*/}

            <CardWrapper border={false} content={false}>
                <Box sx={{ p: 2.25 }}>
                    <Grid container direction="column">
                        <Grid item>
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, mr: 1, mt: 1.75, mb: 0.75 }}>{number}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={{ mb: 1.25 }}>
                            <Typography
                                sx={{
                                    fontSize: '1rem',
                                    fontWeight: 500,
                                    color: theme.palette.secondary.main
                                }}
                            >
                                {text}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </CardWrapper>
        </>
    );
};

export default DashboardCard;
