import React from 'react';
import './App.css';
import {Box, Button, Container, Link, Typography} from "@mui/material";
import NavBar from "./components/NavBar";
import Slider from "./components/Slider";
import Brands from "./components/Brands";
import InfoContainer from "./components/InfoContainer";
import Gallery from "./components/Gallery";
import ContactForm from "./components/Contact/ContactForm";

import i18n from "i18next";
import {initReactI18next, useTranslation} from "react-i18next";
import translationCZ from "./locales/cs/translation.json";
import translationEN from "./locales/en/translation.json";
import {Outlet} from "react-router-dom";
import {InfoToast} from "./components/toast/InfoToast";


const resources = {
    en: {
        translation: translationEN,
    },
    cs: {
        translation: translationCZ,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "cs",
    fallbackLng: "cs",
    interpolation: {
        escapeValue: false,
    },
});


function Copyright() {
  return (
          <Typography variant="body2" color="secondary" align="center">
              {'Copyright © '}
              <Link color="inherit" href="https://mui.com/">
                  Your Website
              </Link>{' '}
              {new Date().getFullYear()}.
              <Button color={"primary"} variant={"contained"}>TEST</Button>
          </Typography>

  );
}

function App() {
  return (
      <>
          <div className="unselectable"> {/* Aplikovat třídu unselectable zde */}
              {/*TODO upravit InfoToast, zmenit na InfoText a umožnit nastavení v administraci*/}
              <InfoToast initialOpen={new Date() < new Date("2024-07-05")}
                         message={"Vážení zákazníci, ve dnech od 1.7.2024 do 4.7.2024 čerpáme dovolenou. Objednávky přijaté během této doby budou vyřízeny následně po našem návratu."}
                         localStorageKey={"info"}/>
              <NavBar />
              <Outlet />
              <ContactForm />
          </div>
      </>
  );
}

export default App;
