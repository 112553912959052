import {createRef, PropsWithChildren} from "react";
import {SnackbarProvider, closeSnackbar} from "notistack";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";

const ToastProvider = ({children}: PropsWithChildren) => {
    const notistackRef = createRef<SnackbarProvider>();

    return (
        <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            action={(snackbarId) => (
                <IconButton onClick={() => closeSnackbar(snackbarId)}>
                    <CloseIcon/>
                </IconButton>
            )}
        >
            {children}
        </SnackbarProvider>
    );
};

export default ToastProvider;