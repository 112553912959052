import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AuthDtoOut} from "../../api/backend/models/auth/AuthDtoOut";
import {AuthToken} from "../../api/backend/models/auth/AuthToken";
import {Role} from "../../api/generated";

export type UserState = {
    userId: number | undefined;
    email?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    roles: Array<Role>;
    token?: AuthToken;
}

export const initialState: UserState = {
    userId: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    companyName: undefined,
    roles: [],
    token: undefined,
};

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLoginBasic: (state, { payload }: PayloadAction<AuthDtoOut>) => {
            console.log("Set basic login")
            return {
                ...state,
                email: payload.user?.email,
                userId: payload.user?.id,
                firstName: payload.user?.firstName,
                lastName: payload.user?.lastName,
                companyName: payload.user?.companyName,
                roles: payload.user?.roles || state.roles,
                token: {
                    accessToken: payload.token?.accessToken,
                    refreshToken: payload.token?.refreshToken,
                    tokenExpiration: payload.token?.tokenExpiration,
                },
            };
        },
        setLogout: (state) => {
          return {
            ...state,
              userId: undefined,
              email: undefined,
              firstName: undefined,
              lastName: undefined,
              companyName: undefined,
              roles: [],
              token: undefined,
          };
        },
    },
})

export const {setLoginBasic, setLogout} = slice.actions;
export default slice.reducer;