export {QueryError};

class QueryError extends Error {
    cause: string;
    statusCode: number;

    constructor(message: string, cause: string, statusCode: number) {
        super(message);
        this.name = 'QueryError';
        this.cause = cause;
        this.statusCode = statusCode;

        // Ensure stack trace is captured correctly
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
    }
}