import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {setLogout} from "../../redux/slice/userSlice";
import {useEffect} from "react";
import {jwtDecode} from "jwt-decode";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import AdminMenu from "./AdminMenu";
import AdminAppBar from "./AdminAppBar";


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AdminPage = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userState = useAppSelector((state) => state.user);
    const location = useLocation();



    // kontrola prihlaseni
    useEffect(() => {
        if (userState.token?.accessToken) {
            // kontrola tokenu
            const token = userState.token?.accessToken;
            if (token) {
                const decodedToken: { exp: number } = jwtDecode(token);
                const currentTime: number = Math.floor(Date.now() / 1000); // Čas v sekundách

                if (decodedToken.exp < currentTime) {
                    // Token vypršel, vyvoláme akci pro odhlášení uživatele
                    dispatch(setLogout());
                    navigate("/login");
                }
            }
        } else {
            if (location.pathname !== "/register") {
                navigate("/login");
            }
        }
    }, [navigate, location.pathname, userState.token?.accessToken]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AdminAppBar />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box>
    );
}

export default AdminPage;