import {Category, Product} from "../../generated";
import {axiosInstance, axiosInstanceWithoutCreadntials, handleRequest} from "../axios";
import {ADMIN_CATEGORY_BY_ID_URL, ADMIN_CATEGORY_URL, ADMIN_PRODUCT_BY_ID_URL, ADMIN_PRODUCT_URL, CATALOG_PRODUCT_BY_ID_URL, CATALOG_PRODUCT_URL} from "../url";
import {AxiosResponse} from "axios/index";


const getAllProducts = (): Promise<Product[]> => {
    return handleRequest(() => axiosInstance.get<Product[]>(ADMIN_PRODUCT_URL));
};

const getAdminProduct = (/*limit:number = 20, offset:number = 0,*/ categoryId:number | null = null, note:string | null = null): Promise<Product[]> => {
    const params = {
        // limit: limit,
        // offset: offset,
        category: categoryId,
        note: note
    };
    return handleRequest(() => axiosInstance.get<Product[]>(ADMIN_PRODUCT_URL, { params }));
};

const getAllCatalogProducts = (): Promise<Product[]> => {
    return handleRequest(() => axiosInstanceWithoutCreadntials.get<Product[]>(CATALOG_PRODUCT_URL));
};

const getCatalogProducts = (limit:number = 20, offset:number = 0, categoryId:number | null = null, note:string | null = null): Promise<Product[]> => {
    const params = {
        limit: limit,
        offset: offset,
        category: categoryId,
        note: note
    };
    return handleRequest(() => axiosInstanceWithoutCreadntials.get<Product[]>(CATALOG_PRODUCT_URL, { params }));
};

const getCatalogProduct = (productId:string | undefined) => {
    return handleRequest(() => axiosInstanceWithoutCreadntials.get<Product>(CATALOG_PRODUCT_BY_ID_URL(productId)));
}

const getRandomCatalogProduct = (limit:number = 8, offset:number = 0): Promise<Product[]> => {
    const params = {
        limit: limit,
        offset: offset,
        random: true
    };
    return handleRequest(() => axiosInstanceWithoutCreadntials.get<Product[]>(CATALOG_PRODUCT_URL, { params }));
};

const postProduct = (product:Product) => {
    return handleRequest(() => axiosInstance.post(ADMIN_PRODUCT_URL, product));
}
//
// const getCategory = (categoryId:number): Promise<AxiosResponse<Category>> => {
//     return handleRequest(() => axiosInstance.get(ADMIN_CATEGORY_BY_ID_URL(categoryId)));
// }
//
const pathProduct = (productId:number, product:Product) => {
    console.log("product", productId, product)
    return handleRequest(() => axiosInstance.patch(ADMIN_PRODUCT_BY_ID_URL(productId), product));
}
//
// const deleteCategory = (categoryId:number): Promise<AxiosResponse<Category>> => {
//     return handleRequest(() => axiosInstance.delete(ADMIN_CATEGORY_BY_ID_URL(categoryId)));
// }

const deleteProduct = (productId:number) => {
    console.log("product", productId)
    return handleRequest(() => axiosInstance.delete(ADMIN_PRODUCT_BY_ID_URL(productId)));
}


const ProductService = {
    getAllProducts,
    getAdminProduct,
    postProduct,
    pathProduct,
    deleteProduct,
    getAllCatalogProducts,
    getCatalogProducts,
    getCatalogProduct,
    getRandomCatalogProduct
}

export default ProductService;