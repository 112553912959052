import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { forwardRef, ReactElement } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import ListItemText from "@mui/material/ListItemText";

const Link = forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
    itemProps,
    ref,
) {
    return <RouterLink ref={ref} {...itemProps} role={undefined}/>;
});

interface ListItemLinkProps {
    icon?: ReactElement;
    primary: string;
    to: string;
    onClick?: () => void;
    sxItem?: object;
    sxIcon?: object;
    sxText?: object;
}

const ListItemLink = (props: ListItemLinkProps) => {
    const {icon, primary, to, onClick, sxItem, sxIcon, sxText} = props;

    return (
        <>
            <ListItem button component={Link} to={to} sx={sxItem} onClick={onClick}>
                {icon ? <ListItemIcon sx={sxIcon}>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} sx={sxText}/>
            </ListItem>
        </>
    );
}

export default ListItemLink;