import {Box, Container, Typography, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// export function useAsset(directory: string) {
//     const [assetUrls, setAssetUrls] = useState<string[]>([]);
//
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(`${directory}`);
//                 console.log("resp", response)
//                 setAssetUrls(response.data);
//             } catch (error) {
//                 console.error(`Failed to load assets from directory: ${directory}`, error);
//             }
//         };
//
//         fetchData();
//     }, [directory]);
//
//     return assetUrls;
// }
const Brands = () => {
    const theme = useTheme();


    const directory = '/assets/img/brands/'; // Název adresáře, ze kterého načítáte obrázky
    const assetUrls= ["bobcat.webp","cat.webp","john-deere.svg","case.webp","kubota.webp","jcb.webp","komatsu.webp","borgwarner.webp",
        "liebherr.webp","new-holland.webp","volvo.webp",
    "ahlmann.webp","kobelco.png", "corteco.png", "mahle.png", "cummins.png",
        "terex.webp", "delphi.png","massey-ferguson.png", "denso.webp", "deutz.png","perkins.png",
        "doosan.webp", "eaton.webp", "garrett.png", "yanmar.webp", "hyster.png",
    "hyundai-construction-equipment.webp", "ihi.webp", "isuzu.webp", "manitou.png", "parker-hannifin.png",
    "stabilus.webp", "zf.webp","bosch-rexroth.svg", "nachi-fujikoshi-corp.svg",  "wacker-neuson.svg"];

    const settings = {
        dots: false,
        infinite: true,
        swipeToSlide: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1000,
        // cssEase: "linear",
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    swipeToSlide: false,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    swipeToSlide: false,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    // initialSlide: 2,
                    swipeToSlide: false,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    // initialSlide: 2,
                    swipeToSlide: false,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: false,
                    infinite: true,
                    dots: false
                }
            }
        ]
    };

    return (
        <Container maxWidth="xl"
                   sx={{
                       paddingTop: '34px',
                       paddingBottom: '32px',

        }}>
            {/*<Typography lineHeight={"2.25rem"} fontSize={"1.875rem"} variant={"h2"} textTransform={"uppercase"}>*/}
            {/*    Specializujeme se na náhradní díly značek:*/}
            {/*</Typography>*/}
            <Slider {...settings}>
                {assetUrls.map((url, index) => {
                    const cleanedAlt = `Logo ${url.replace('.webp', '')}`;

                    return (
                        <Box
                            key={index}
                            component="img"
                            src={`${directory}${url}`} // Použití string interpolation pro src
                            alt={cleanedAlt} // Čistý alt bez přípony .webp
                            sx={{
                                height: {
                                    xl: '40px',
                                    xs: '40px'
                                },
                                width: {
                                    xl: '135px',
                                    xs: '135px'
                                },
                                objectFit: 'contain',
                                marginRight: '16px',
                            }}
                        />
                    );
                })}
            </Slider>
        </Container>
    );
};

export default Brands;