import {configureStore } from "@reduxjs/toolkit"
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import reduces from "./reduces";

const persistConfig = {
    key: 'root',
    storage,
}
/*
 TODO PersistedStorage jen na USER, teď se ukládá celý store
 TODO Zmenit kontrolu expirace tokenu, a pouzit i refresh token pro obnovu accesstokenu. Nasledne snizit expiraci z 12 hodin na 1 hodinu.
 */
const persistedReducer = persistReducer(persistConfig, reduces)
export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
});

export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store)