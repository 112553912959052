import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useNavigate} from "react-router-dom"
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {useFormik} from "formik";
import {userBasicLogin} from "../../redux/action-creator/userBasicLogin";
import {useEffect} from "react";
import {useToast} from "../toast/useToast";
import * as Yup from "yup";




export default function LoginUser() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { showToast } = useToast();

    const SignupSchema = Yup.object().shape({
        email: Yup.string()
            .email('Naplatný email.')
            .required('Povinné'),
        password: Yup.string()
            .required('Heslo nebylo vyplněno.')
    });

    const formik = useFormik({
       initialValues: {
           email: "",
           password: "",
       },
        validationSchema: SignupSchema,
        onSubmit: (values) => {
           dispatch(userBasicLogin(values, showToast));
        }
    });

    const userState = useAppSelector((state) => state.user);

    useEffect(() => {
        if (userState.token?.accessToken) {
            console.log("useEffect navigate: /")
            navigate("/admin");
        } else {
            console.log("useEffect navigate: /login")
            navigate("/login")
        }
    }, [navigate, userState.token?.accessToken]);





    return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Přihlášení
                    </Typography>
                    <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mailová adresa"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Heslo"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                        {/*<FormControlLabel*/}
                        {/*    control={<Checkbox value="remember" color="primary" />}*/}
                        {/*    label="Remember me"*/}
                        {/*/>*/}
                        <Button
                            type="submit"
                            color="primary"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Přihlásit
                        </Button>
                    </Box>
                </Box>
            </Container>
    );
}
