import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {Button} from "@mui/material";
import * as React from "react";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import AdminMenu from "./AdminMenu";
import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {setLogout} from "../../redux/slice/userSlice";


interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const AdminAppBar = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const userState = useAppSelector((state) => state.user);

    const handleDrawerOpen = () => {
        setOpen(!open);
    };

    const logOut = () => {
        dispatch(setLogout());
    };

    return (
        <>
            <AppBar position="fixed" open={open} color={"secondary"}>
                <Toolbar>
                    <Box display='flex' flexGrow={1}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 3,
                                // ...(open && { display: 'none' }),
                            }}
                        >
                            {!open ? <MenuIcon /> : <CloseIcon />}
                        </IconButton>
                        <img
                            className="nav-logo"
                            src={`/assets/img/logo.svg`}
                            alt="Strojparts s.r.o."
                        />
                    </Box>

                    <Box display='flex' pr={3}>
                        {userState.token?.accessToken && (
                            <div>
                                <Button onClick={logOut} color="primary">Odhlásit</Button>
                            </div>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <AdminMenu open={open}/>
        </>

    );
}

export default AdminAppBar;