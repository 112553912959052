import {Box, Button, Container, Divider, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const headerBgStyle = {
    background: `linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), url('/assets/img/header-bg.webp')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '85vh',
    backgroundBlendMode: 'multiply',
};
const Slider = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = () => {
        const inquirySection = document.getElementById('inquiry');
        if (inquirySection) {
            inquirySection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <section style={headerBgStyle}>
            <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', height: '100%' }}>
                <Box
                    component="img"
                    src="/assets/img/logo.svg"
                    alt="Logo"
                    sx={{
                        height: '96px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '32px',
                        [theme.breakpoints.up("md")]: {
                            height: '160px',
                            marginBottom: '80px',
                            marginLeft: 0,
                            marginRight: 0,
                        },
                    }}
                />
                <Typography
                    variant={"h1"}
                    sx={{
                        fontSize: '2.25rem',
                        lineHeight: '40px',
                        color: '#fff',
                        [theme.breakpoints.down("md")]: {
                            fontSize: '1.5rem',
                            textAlign: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                        },
                    }}
                >
                    <Box>{t("home.slider_title_1")}</Box><Box pt={3}>{t("home.slider_title_2")}</Box>
                </Typography>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleNavigate}
                    sx={{
                        textTransform: 'uppercase',
                        fontSize: '1.25rem',
                        marginTop: '56px',
                        paddingLeft: '48px',
                        paddingRight: '48px',
                        fontWeight: 700,
                        [theme.breakpoints.down("md")]: {
                            fontSize: '1.5rem',
                            textAlign: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                        },
                    }}
                >
                    {t("looking_for_part")}
                </Button>
            </Container>
            <Divider sx={{ height: '5px', backgroundColor: '#f0c031', width: '100%', position: 'absolute' }} />
        </section>
    );
};

export default Slider;