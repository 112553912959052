import Container from "@mui/material/Container";
import {Box, Button, Card, CardContent, CardMedia, CircularProgress, Grid, Typography, useTheme} from "@mui/material";
import {useInfiniteQuery, useQuery} from "@tanstack/react-query";
import {Product} from "../api/generated";
import {NotFoundError} from "../api/backend/NotFound";
import ProductService from "../api/backend/service/ProductService";
import {useNavigate} from "react-router-dom";
import {PRODUCT_DIR} from "../resources/constants";
import {useTranslation} from "react-i18next";

const RandomProductPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const directory = PRODUCT_DIR || "https://strojparts.cz/assets/products/img/";
    const { t } = useTranslation();



    const {data, status, isLoading, error, isError} =
        useQuery<Product[], NotFoundError>({queryKey: ['products'], queryFn: () => ProductService.getRandomCatalogProduct()})

    const handleButtonClick = () => {
        navigate("/catalog");
        window.scrollTo(0, 0);
    };

    return (
        <Container maxWidth="xl"
                   sx={{
                       paddingTop: '32px',
                       paddingBottom: '32px',
                   }}>
            <Typography
                variant="h3"
                sx={{
                    fontSize: '28px',
                    lineHeight: '36px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    borderBottom: `4px solid ${theme.palette.primary.main}`,
                    width: 'fit-content',
                    marginX: 'auto',
                    position: 'relative',
                    '@media (min-width: 1200px)': {
                        fontSize: '3xl',
                    },
                }}
            >
                {t('product_catalog_page.product_catalog')}
            </Typography>
            <Grid container spacing={2} pt={2}>
                {data?.map(product => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                        <a href={`/product/${product.id}/${product.path}`} style={{textDecoration: "none"}}>
                            <Card sx={{
                                height: '100%',
                                cursor: 'pointer',
                                transition: 'transform 0.2s, box-shadow 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                                },
                            }}
                            >
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={product.productPicture && product.productPicture.length > 0 ? directory+product.productPicture[0].filename : '/assets/img/logo.svg'}
                                    alt={product.name}
                                    sx={{height: 350}}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div"  sx={{fontWeight: 700}}>
                                        {product.name}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        fontWeight: 600
                                    }}>
                                        {product.note}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </a>
                    </Grid>
                ))}
            </Grid>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                {isLoading ? (
                    <CircularProgress/>
                ) : (
                    <Button variant="contained" onClick={handleButtonClick} /*href={"/catalog"}*/>
                        Zobrazit celý katalog
                    </Button>
                )}
                {!data && !isLoading && (
                    <div>Produkty nenalezeny.</div>
                )}
            </div>
        </Container>
    );
}

export default RandomProductPage;