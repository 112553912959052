import axios, {AxiosError} from "axios";
import {API_SERVER_URL} from "../../resources/constants";
import {store} from "../../redux/store";
import {AxiosResponse} from "axios/index";
import {NotFoundError} from "./NotFound";
import {QueryError} from "./QueryError";
import {Error} from "./../generated/models/Error";

const backendURL = API_SERVER_URL + '/api/v1';
// const backendURL = 'http://dev.backoffice.viewcz.lindosoft.cz/api/v1';

export const axiosInstance = axios.create({
    baseURL: backendURL,
    withCredentials: true,
});
export const axiosInstanceWithoutCreadntials = axios.create({
    baseURL: backendURL,
    withCredentials: false,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const userState = store.getState().user;
        if (userState.token?.accessToken) {
            config.headers['Authorization'] = 'Bearer ' + userState?.token.accessToken
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)
axiosInstanceWithoutCreadntials.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)


export const handleRequest = <T>(axiosRequest: () => Promise<AxiosResponse<T>>): Promise<T> => {
    return axiosRequest()
        .then((response) => {
            console.log("Odpověď:", response);
            return response.data;
        }).catch((error: AxiosError<Error>) => {
            console.error("Error:", error);
            if (error.response && error.response.data) {
                if (error.response.status === 404) {
                    throw new NotFoundError("404 - Nenalezeno", "Cause");
                } else {
                    // TODO udelat dalsi errory (401, 409 apod).
                    throw new QueryError(error.response.data.message || "Neznama chyba", error.response.data.detail || "Neznama pricina", error.response.status);
                }
            } else if (error.response && error.response.status === 404) {
                throw new NotFoundError("404 - Nenalezeno", "Cause");
            } else {
                throw new QueryError(error.message || "Neznama chyba", "Neznama pricina", error.status || 500);
            }
        });
};

