import {Box, Container, Divider, Grid, Paper, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";


const InfoContainer = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const parts = [
        {
            title: t("home.parts_1"),
            description:  t("home.parts_1_desc"),
            img: 'img/parts/part-1.webp'
        },
        {
            title:  t("home.parts_2"),
            description:  t("home.parts_2_desc"),
            img: 'img/parts/part-2.webp'
        },
        {
            title:  t("home.parts_3"),
            description:  t("home.parts_3_desc"),
            img: 'img/parts/part-3.webp'
        },
        {
            title:  t("home.parts_4"),
            description:  t("home.parts_4_desc"),
            img: 'img/parts/part-4.webp'
        }
    ]

    return (
        <>
            <Container maxWidth={false} sx={{
                paddingTop: '64px',
                paddingBottom: '64px',
                boxShadow: 'inset 0 0 #0000, inset 0 25px 35px -12px rgba(0, 0, 0, 0.25), ' +
                    'inset 0 -25px 35px -12px rgba(0, 0, 0, 0.25)',
            }}>
                <Container maxWidth="xl">
                    <Grid container spacing={2}>
                        {
                            parts.map(value => {
                                return (
                                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                                        <Box
                                            sx={{
                                                width: '300px',
                                                marginX: 'auto',
                                                overflow: 'hidden',
                                                position: 'relative',
                                                transition: 'transform 0.3s',
                                                '&:hover': {
                                                    transform: 'scale(1.1)',
                                                },
                                            }}
                                        >
                                            <img
                                                src={`/assets/${value.img}`}
                                                alt={value.title}
                                                loading="lazy"
                                                style={{width: '100%', height: 'auto'}}
                                            />
                                        </Box>
                                        <Typography
                                            variant="h3"
                                            sx={{
                                                fontSize: '28px',
                                                lineHeight: '36px',
                                                fontWeight: 'bold',
                                                textTransform: 'uppercase',
                                                borderBottom: `4px solid ${theme.palette.primary.main}`,
                                                width: 'fit-content',
                                                marginX: 'auto',
                                                position: 'relative',
                                                '@media (min-width: 1200px)': {
                                                    fontSize: '3xl',
                                                },
                                            }}
                                        >
                                            {value.title}
                                        </Typography>
                                        <Typography sx={{
                                            zIndex: 50,
                                            marginX: 'auto',
                                            fontSize: '24px',
                                            lineHeight: '32px',
                                            textAlign: 'center',
                                            width: 'fit-content',
                                            '@media (min-width: 1200px)': {
                                                fontSize: '3xl',
                                            },
                                        }}>
                                            {value.description}
                                        </Typography>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </Container>
            </Container>
        </>
    );
}

export default InfoContainer;